import {
  SET_ALL_PUBLICATIONS,
  SET_ALL_PUBLICATIONS_TOPICS,
  SET_PUBLICATIONS_SELECTED_TOPIC,
  SET_FILTERED_PUBLICATIONS
} from '../action-types';
import store from '../store/store';

export const setAllPublications = (allPublications) => {
  return { type: SET_ALL_PUBLICATIONS, allPublications };
};

export const setAllPublicationTopics = (allPublicationTopics) => {
  return { type: SET_ALL_PUBLICATIONS_TOPICS, allPublicationTopics };
};

export const setPublicationsSelectedTopic = (selectedTopic) => {
  return { type: SET_PUBLICATIONS_SELECTED_TOPIC, selectedTopic };
};

export const setFilteredPublications = (filteredPublications) => {
  return { type: SET_FILTERED_PUBLICATIONS, filteredPublications };
};

export const filterPublications = () => {
  const state = store.getState();
  const { selectedTopic } = state.publicationsFilter;
  const { allPublications } = state.publicationsFilter;
  const filteredPublications = allPublications.filter((publication) => {
    if (selectedTopic === '') return true;
    return publication.topic.slug === selectedTopic;
  });
  return (dispatch) => {
    dispatch(setFilteredPublications(filteredPublications));
  };
};
