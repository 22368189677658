import React, { useState } from 'react';
import CardPublication from '../CardPublication';
import useMatchMedia from '../../hooks/match-media';

const ListCardsPublication = ({ cards, isFeed }) => {
  /** Media rule to match on resize */
  const [isMobile, setIsMobile] = useState(false);
  const mediaToMatch = '(max-width: 1023px)';
  /** Using external HOOKS to listen window resize */
  useMatchMedia(setIsMobile, mediaToMatch);

  return (
    <>
      {
        cards && cards.map((card) => {
          const {
            coverImage, title, id, slug
          } = card;
          const label = card.topic.name;
          const topicSlug = card.topic.slug;
          const { fluid, alt } = coverImage || {};

          if (isFeed) {
            return (
              <div key={id} className={`${isMobile && isFeed ? 'swiper-slide feed__slide' : ''}`}>
                <CardPublication
                  image={fluid}
                  label={label}
                  title={title}
                  alt={alt}
                  topicSlug={topicSlug}
                  slug={slug}
                />
              </div>
            );
          }
          return (
            <CardPublication
              key={id}
              image={fluid}
              label={label}
              title={title}
              alt={alt}
              slug={slug}
              topicSlug={topicSlug}
            />
          );
        })
      }
    </>
  );
};

export default ListCardsPublication;
