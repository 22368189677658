import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Pagination from '../Pagination';
import ListCardsPublication from '../ListCardsPublication';
import { useResetPage, useChangeDisplayPagesOnResize } from '../../hooks/archive-hooks';

const PublicationsSearchResults = ({ filteredPublications, selectedTopic }) => {
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayPages, setDisplayPages] = useState(10);

  const itemsPerPage = 12;

  const handleChangePage = (pageResults, page) => {
    setResults(pageResults);
    setCurrentPage(page);
  };

  useResetPage(setCurrentPage, selectedTopic);
  useChangeDisplayPagesOnResize(setDisplayPages);

  useEffect(() => {
    if (filteredPublications.length <= itemsPerPage) {
      setResults(filteredPublications);
    }
  }, [filteredPublications]);

  return (
    <section className="publications-archive-results">
      <div className="list-cards-publication">
        <ListCardsPublication cards={results} />
      </div>
      { filteredPublications
        && filteredPublications.length > itemsPerPage && (
          <Pagination
            items={filteredPublications}
            handleChangePage={handleChangePage}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            displayPages={displayPages}
          />
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    filteredPublications: state.publicationsFilter.filteredPublications,
    selectedTopic: state.publicationsFilter.selectedTopic
  };
};

export default connect(mapStateToProps, null)(PublicationsSearchResults);
