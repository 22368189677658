import React from 'react';
import Container from '../Container';
import PublicationsList from '../PublicationsList';

const HeaderPublications = ({ title, subtitle, topics }) => {
  return (
    <Container className="header-page header-page--only-text header-page--publications">
      <div className="header-page__wrapper">
        <div className="header-page__container">
          <span className="header-page__subtitle label-small">{subtitle}</span>
          <h1 className="header-page__title heading-xl">{title}</h1>
        </div>
      </div>
      <PublicationsList topics={topics} />
    </Container>
  );
};

export default HeaderPublications;
