import React from 'react';
import { graphql } from 'gatsby';
import PublicationsFilterGlobals from '../components/PublicationsFilterGlobals/publications-filter-globals';
import HeaderPublications from '../components/HeaderPublications';
import Layout from '../containers/layout';
import PublicationsSearchResults from '../components/PublicationsSearchResults';
import VisualComposer from '../components/VisualComposer';

const PublicationArchive = ({ data, pageContext }) => {
  const { allPublications } = { allPublications: data.allDatoCmsPubblicazione.nodes };
  const { allPublicationTopics } = { allPublicationTopics: data.allDatoCmsTopicPublication.nodes };
  const { visualComposer } = { visualComposer: data.datoCmsPublicationArchive.visualComposer };
  const { title, label, seoMetaTags } = data.datoCmsPublicationArchive;
  return (
    <Layout
      seoMetaTags={seoMetaTags}
      pathname={pageContext.pathname}
      communicationBar={pageContext.communicationBar}
      pageType="Publication Archive"
      canonical={pageContext.canonical}
    >
      <PublicationsFilterGlobals
        allPublications={allPublications}
        allPublicationTopics={allPublicationTopics}
      />
      <HeaderPublications
        title={title}
        subtitle={label}
      />
      <div className="publications-archive-results__wrapper">
        <PublicationsSearchResults />
      </div>
      {/* VisualComposer */}
      <VisualComposer
        components={visualComposer}
      />
    </Layout>
  );
};

export const DatoCmsPubblications = graphql`
  query {
    datoCmsPublicationArchive {
      id
      seoMetaTags {
        tags
      }
      model {
        apiKey
      }
      label
      title
      slug
      visualComposer {
        ...BannerText
        ...CenteredNumbers
        ...Numbers
        ...Spacing
      }
    }
    allDatoCmsPubblicazione(sort: {fields: publicationDate, order: DESC}) {
      nodes {
        originalId
        title
        slug
        coverImage {
            alt
            fluid(
              imgixParams: {fm: "jpg", auto: "compress", dpr: 3},
              sizes: "(min-width: 48em) 400px, 100vw"
            ) {
              ...GatsbyDatoCmsFluid
            }
        }
        topic {
          slug
          name
        }
      }
    }
    allDatoCmsTopicPublication {
      nodes {
        slug
        name
      }
    }
  }
`;

export default PublicationArchive;
