import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  setAllPublications,
  setAllPublicationTopics,
  filterPublications
} from '../../actions/publications-filter-actions';

const PublicationsFilterGlobals = ({
  selectedTopic,
  allPublications,
  setAllPublications,
  filterPublications,
  allPublicationTopics,
  setAllPublicationTopics
}) => {
  useEffect(() => {
    setAllPublications(allPublications);
    setAllPublicationTopics(allPublicationTopics);
  }, []);

  useEffect(() => {
    filterPublications();
  }, [selectedTopic]);
  return (<></>);
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAllPublications: (allPublications) => dispatch(setAllPublications(allPublications)),
    setAllPublicationTopics: (allPublicationTopics) => dispatch(
      setAllPublicationTopics(allPublicationTopics)
    ),
    filterPublications: () => dispatch(filterPublications())
  };
};

const mapStateToProps = (state) => {
  return {
    selectedTopic: state.publicationsFilter.selectedTopic
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicationsFilterGlobals);
