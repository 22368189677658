import React from 'react';
import { connect } from 'react-redux';
import { setPublicationsSelectedTopic } from '../../actions/publications-filter-actions';

const PublicationsList = ({ topics, setPublicationsSelectedTopic, selectedTopic }) => {
  const handleClick = (e, value) => {
    e.preventDefault();
    setPublicationsSelectedTopic(value);
  };

  return (
    <div className="publications-list">
      <ul className="publications-list__list">
        {
          topics && topics.map((topic) => {
            const label = topic.name;
            const value = topic.slug;
            const isSelected = (selectedTopic === value);
            return (
              <li
                key={topic.slug}
                className={`publications-list__item  ${isSelected ? 'publications-list__item--active' : ''}`}
              >
                <button
                  type="button"
                  className="publications-list__btn label-small"
                  onClick={(e) => handleClick(e, value)}
                >
                  {label}
                </button>
              </li>
            );
          })
        }
      </ul>
    </div>
  );
};

PublicationsList.defaultProps = {
  menu: [
    {
      label: 'Tutti',
      url: ''
    }
  ]
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPublicationsSelectedTopic: (selectedTopic) => {
      return dispatch(setPublicationsSelectedTopic(selectedTopic));
    }
  };
};

const mapStateToProps = (state) => {
  return {
    topics: state.publicationsFilter.allTopics,
    selectedTopic: state.publicationsFilter.selectedTopic
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicationsList);
