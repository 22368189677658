/* eslint-disable */
import React from 'react';
import { navigate } from 'gatsby';
import Link from '../TransitionLink';
import Img from 'gatsby-image';
import { connect } from 'react-redux';
import { setPublicationsSelectedTopic } from '../../actions/publications-filter-actions';

const CardPublication = ({
  alt,
  slug,
  label,
  image,
  title,
  topicSlug,
  dispatchSetPublicationsSelectedTopic
}) => {
  const handleNavigate = () => {
    navigate('/pubblicazioni');
    dispatchSetPublicationsSelectedTopic(topicSlug);
  };
  return (
    <Link className="publication-card__link" to={`/pubblicazioni/${slug}/`}>
      <article className="publication-card">
        <figure className="publication-card__image-container">
          <Img
            className="publication-card__image"
            alt={alt}
            fluid={image}
          />
        </figure>
        <button className="publication-card__label-btn" type="button" onClick={() => handleNavigate()}>
          <span className="publication-card__label label-small">{label}</span>
        </button>
        <h3 className="publication-card__title heading-xs">{title}</h3>
      </article>
    </Link>
  );
};

export const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSetPublicationsSelectedTopic: (selectedTopic) => {
      dispatch(setPublicationsSelectedTopic(selectedTopic));
    }
  };
};

export default connect(null, mapDispatchToProps)(CardPublication);
